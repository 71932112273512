export const genEChartsOption = (data, options = {}) => {
    const { addRMBIcon = true, conditionalLabel = false, showXAxis = false, smooth = false,
        markPointTemplate = '', showLabel = false, showSymbol = false,
        showYSplitLine = false, seriesSymbol = 'circle', totalHours = 24 } = options,
        xDataList = data.map(item => item.x),
        yDataList = data.map(item => item.y),
        showLabelY = (dataIndex) => {
            const currentData = data[dataIndex]
            if (currentData && conditionalLabel && !currentData.labelY) {
                return false
            } else {
                return dataIndex > 0 ? !showLabelY(dataIndex - 1) : true
            }
        },
        markPointData = data.filter((item, index) => showLabelY(index)).map((item) => ({ coord: [data.indexOf(item), item.y] })),
        labelYOffset = 45,
        markpointTemplateMap = {
            line: {
                symbol: 'path://m266,78.4375l1,0l0,227l-1,0l0,-227z',
                symbolOffset: [0, -1 * labelYOffset / 2 - 3],
                symbolKeepAspect: true,
                symbolSize: [2, labelYOffset],
                data: markPointData,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                            { offset: 0, color: '#009EE1' },
                            { offset: 1, color: 'white' }
                        ],
                        globalCoord: false
                    }
                }
            },
            circle: {
                symbol: 'path://m255,173.4375c0,-12.70718 10.29282,-23 23,-23c12.70718,0 23,10.29282 23,23c0,12.70718 -10.29282,23 -23,23c-12.70718,0 -23,-10.29282 -23,-23z',
                symbolKeepAspect: true,
                symbolSize: 18,
                data: [{ coord: [totalHours, data[data.length - 1].y] }],
                itemStyle: {
                    color: {
                        type: 'radial',
                        x: .5,
                        y: .5,
                        r: .5,
                        colorStops: [
                            { offset: 1, color: 'rgba(0,158,225, .3)' },
                            { offset: .36, color: 'rgba(0,158,225, .3)' },
                            { offset: .35, color: 'rgba(0,158,225, 1)' },
                            { offset: 0, color: 'rgba(0,158,225, 1)' }
                        ],
                        globalCoord: false
                    }
                }
            }
        },
        todayPercent = Math.max(0, data.findIndex(item => item.x === '今天买')) / (data.length - 1),
        xAxisOptions = showYSplitLine ? {
            max: totalHours,
            min: 0,
            interval: totalHours / 4
        } : {
                data: xDataList
            }

    return {
        animation: false,
        textStyle: {
            color: "#999"
        },
        grid: {
            top: showYSplitLine ? 10 : (labelYOffset + 10),
            left: showYSplitLine ? 70 : 20,
            right: 20,
            bottom: showYSplitLine ? 30 : 43,
            tooltip: {
                show: false
            }
        },
        xAxis: [{
            type: showYSplitLine ? 'value' : 'category',
            boundaryGap: ['10%', '10%'],
            splitLine: false,
            ...xAxisOptions,
            axisLine: {
                show: showXAxis,
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: '#999'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: showYSplitLine ? undefined : 0,
                formatter: function (params, _index) {
                    const currentData = data.find(item => item.x === params) // data[index]
                    if (showYSplitLine) {
                        return currentData ? currentData.xAxisLabel : ''
                    } else {
                        if (currentData && conditionalLabel && !currentData.labelX) {
                            return ''
                        } else {
                            return params
                        }
                    }
                },
                textStyle: {
                    color: function (value, _index) {
                        let thePoint = data.find(item => item.x == value)
                        return thePoint && thePoint.highlightX ? '#0086f6' : '#999999'
                    }
                }
            }
        }],
        yAxis: [{
            show: showYSplitLine,
            axisLabel: {
                formatter: (value) => {
                    return '￥' + Math.ceil(value)
                }
            },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true, lineStyle: { color: 'rgba(239,239,239,.7)' } },
            min: (value) => value.min * .95,
            max: (value) => value.max * (showYSplitLine ? 1 : 1.2),
        }],
        series: [{
            type: 'line',
            symbol: seriesSymbol,
            symbolSize: (value, params) => {
                return showSymbol && (showLabelY(params.dataIndex)) ? 6 : 0
            },
            smooth,
            data: showYSplitLine ? data.map(item => ([item.x, item.y])) : yDataList,
            markPoint: markpointTemplateMap[markPointTemplate],
            label: showLabel ? {
                normal: {
                    formatter: function (params) {
                        return showLabelY(params.dataIndex) ? ((addRMBIcon ? '¥' : '') + params.value) : ''
                    },
                    show: true,
                    distance: labelYOffset,
                    position: 'top',
                    borderColor: '#009EE1',
                    borderRadius: 5,
                    borderWidth: 1,
                    padding: [2, 1],
                    color: "#009EE1"
                }
            } : undefined,
            itemStyle: {
                normal: {
                    color: "#009EE1",
                    lineStyle: {
                        color: "#009EE1"
                    },
                    areaStyle: showYSplitLine ? undefined : {
                        type: 'default',
                        opacity: .2,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [
                                { offset: 0, color: 'grey' },
                                { offset: todayPercent, color: 'grey' },
                                { offset: todayPercent + .01, color: '#009EE1' },
                                { offset: 1, color: '#009EE1' }
                            ],
                            globalCoord: false
                        }
                    }
                }
            }
        }]
    }
}