import moment from 'moment'
import { PRICE_TREND_MAP, GUIDE_LINE_TAG_MAP } from "../../../../sources/list/guideLineConst";

export const processGuideLine = (props) => {
    const { expand, activeTag, pricePrediction, priceChangeHistory, travelTips,
        firstPrevCondSegment, onChangeActiveTag, onToggleExpand,
        onPopTravelSpotImage } = props

    const priceTrendFlag = pricePrediction && pricePrediction.get('priceTrendFlag'),	// -1: 下降  0：变化不大  1：上涨
        pricePredictionDataList = [],
        priceChangeHistoryDataList = [],
        priceTrendMapItem = PRICE_TREND_MAP[priceTrendFlag] || {},
        emptyActiveTag = activeTag === '',
        activePricePrediction = pricePrediction ? emptyActiveTag || activeTag === GUIDE_LINE_TAG_MAP.PRICE_PREDICTION : false,
        activePriceChangeHistory = priceChangeHistory ? (emptyActiveTag && !activePricePrediction) || activeTag === GUIDE_LINE_TAG_MAP.PRICE_CHANGE_HISTORY : false,
        activeTravelTips = travelTips ? (emptyActiveTag && !activePricePrediction && !activePriceChangeHistory) || activeTag === GUIDE_LINE_TAG_MAP.TRAVEL_TIPS : false,
        firstDepartureCityTimeZone = firstPrevCondSegment.get('departureCityTimeZone'),
        firstDepartureDate = firstPrevCondSegment.get('departureDate'),
        firstDepartureDateMoment = moment(firstDepartureDate),
        firstDepartureDateStr = firstDepartureDateMoment.isValid() ? firstDepartureDateMoment.format('MM/DD') : '',
        travelTipsHasHolidaysTips = travelTips && travelTips.get('holidaysTips'),
        travelTipsHasVisaTips = travelTips && travelTips.get('visaTips'),
        travelTipsHasHotSpotsList = travelTips && travelTips.get('hotSpotsList') && travelTips.get('hotSpotsList').size > 0,
        //根据景点图片、景点信息和签证信息的存在情况调整样式，下面三种情况是互斥的
        //1.有景点图片，但景点信息和签证信息只有一个
        travelTipsHotspotWithOneTextStyle = (travelTipsHasHolidaysTips || travelTipsHasVisaTips) && !(travelTipsHasHolidaysTips && travelTipsHasVisaTips) && travelTipsHasHotSpotsList,
        //2.没有景点图片，但景点信息和签证信息只有一个
        travelTipsNoHotspotWithOneTextStyle = (travelTipsHasHolidaysTips || travelTipsHasVisaTips) && !(travelTipsHasHolidaysTips && travelTipsHasVisaTips) && !travelTipsHasHotSpotsList,
        //3.没有景点图片，但景点信息和签证信息两个都有
        travelTipsNoHotspotWithTwoTextsStyle = (travelTipsHasHolidaysTips && travelTipsHasVisaTips) && !travelTipsHasHotSpotsList,
        travelTextClassName = travelTipsHotspotWithOneTextStyle ? 'guide-text-spot-with-one-text' : (travelTipsNoHotspotWithOneTextStyle ? 'guide-text-no-spot-with-one-text' : (travelTipsNoHotspotWithTwoTextsStyle ? 'guide-text-no-spot-with-two-texts' : '')),
        pricePredictionIndicatorWidth = 163,    //每个tab的宽度，价格预测  158,  价格历史 147， 旅行贴士 130
        priceChangeHistoryIndicatorWidth = 150,
        travelTipsTagName = travelTips ? travelTips.get('tagName', '') : '',
        travelTipsIndicatorWidth = travelTipsTagName.indexOf('赞！') === 0 ? (36 + (travelTipsTagName.length - 2) * 17) : travelTipsTagName.length * 17,
        tabHeaderPaddingLeft = 32,
        pricePredictionIndicatorLeft = 184,
        priceChangeHistoryIndicatorLeft = pricePredictionIndicatorLeft + (pricePrediction ? (pricePredictionIndicatorWidth + tabHeaderPaddingLeft) : 0),
        travelTipsIndicatorLeft = priceChangeHistoryIndicatorLeft + (priceChangeHistory ? (priceChangeHistoryIndicatorWidth + tabHeaderPaddingLeft) : 0),
        tabHeaderIndicatorLeft = activePricePrediction ? pricePredictionIndicatorLeft : (activePriceChangeHistory ? priceChangeHistoryIndicatorLeft : travelTipsIndicatorLeft),
        tabHeaderIndicatorWidth = activePricePrediction ? pricePredictionIndicatorWidth : (activePriceChangeHistory ? priceChangeHistoryIndicatorWidth : travelTipsIndicatorWidth)

    let priceChangeTotalHours = 24

    if (pricePrediction) {
        //需要标记价格：历史边缘价、当前价格、未来最低价、未来最高价
        //需要标记日期：历史边缘日历、当前价格日期、未来最低价日期、未来最高价日期、出发日期
        pricePrediction.get('dataList').valueSeq().forEach(data => {
            const today = data.get('today')
            pricePredictionDataList.push({
                x: today ? '今天买' : data.get('time'),
                y: data.get('price'),
                today
            })
        })

        let lastLabeledDataIndex = -100
        const todayDataIndex = pricePredictionDataList.findIndex(data => data.today)     //先看下是否有当天数据，用来判断数据集合中每个数据点是历史数据还是预测数据

        const allPriceListInFuture = pricePredictionDataList.filter((_data, index) => index > todayDataIndex).map(data => data.y),
            minPriceInFuture = Math.min(...allPriceListInFuture),
            maxPriceInFuture = Math.max(...allPriceListInFuture)

        let hasMarkFutureMinPrice = false,
            hasMarkFutureMaxPrice = false

        pricePredictionDataList.forEach((data, index) => {
            const toLastLabeledPointNotNear = ((index - lastLabeledDataIndex) >= 2)    //不允许相邻点都显示x，可能使x标签重叠

            if (todayDataIndex > 0 && index === 0) {
                //历史边缘点
                data.labelY = true
                data.labelX = toLastLabeledPointNotNear
                lastLabeledDataIndex = index
            } else if (index === todayDataIndex) {
                //当天购买价格
                data.labelY = true
                data.labelX = toLastLabeledPointNotNear
                data.highlightX = true
                lastLabeledDataIndex = index
            } else if (index > todayDataIndex) {
                if (firstDepartureCityTimeZone === 480 && firstDepartureDateStr && data.x === firstDepartureDateStr) {
                    //出发地时区与北京时区一致时，显示出发日，否则不显示
                    data.x = `出发日\n${data.x}`
                    data.labelY = toLastLabeledPointNotNear && (data.y === maxPriceInFuture || data.y === minPriceInFuture)
                    data.highlightX = true
                    data.labelX = toLastLabeledPointNotNear
                    lastLabeledDataIndex = index
                } else if (data.y === minPriceInFuture && !hasMarkFutureMinPrice) {
                    //未来最低价日期
                    hasMarkFutureMinPrice = true
                    data.labelY = true
                    data.labelX = toLastLabeledPointNotNear
                    lastLabeledDataIndex = index
                } else if (data.y === maxPriceInFuture && !hasMarkFutureMaxPrice) {
                    //未来最高价日期
                    hasMarkFutureMaxPrice = true
                    data.labelY = true
                    data.labelX = toLastLabeledPointNotNear
                    lastLabeledDataIndex = index
                }
            }
        })
    }

    if (priceChangeHistory) {
        let totalHours = priceChangeHistory.get('hours') || 24,
            // 根据总时长将横轴分成4段，比如总时长24小时：【24小时，18小时，12小时，6小时】；总时长48小时：【48小时，36小时，24小时，12小时】
            labelXDataList = [totalHours, totalHours * 3 / 4, totalHours / 2, totalHours / 4].map(hours => hours * 3600),
            getTheEntry = (intervalSeconds, price) => {
                const xAxisLabel = intervalSeconds > 0 ? (labelXDataList.indexOf(intervalSeconds) >= 0 ? `${intervalSeconds / 3600}小时前` : '') : '此刻'

                return {
                    rawX: intervalSeconds,
                    x: (totalHours * 3600 - intervalSeconds) / 3600,
                    xAxisLabel: xAxisLabel,
                    y: price,
                    labelX: !!xAxisLabel,
                    labelY: false,
                    highlightX: intervalSeconds === 0
                }
            }

        priceChangeTotalHours = totalHours

        priceChangeHistory.get('dataList').valueSeq().forEach((data, _index) => {
            const intervalSeconds = data.get('intervalSeconds'),
                price = data.get('price')

            priceChangeHistoryDataList.push(getTheEntry(intervalSeconds, price))
        })

        labelXDataList.forEach((intervalSeconds) => {
            const index = priceChangeHistoryDataList.findIndex(item => item.rawX === intervalSeconds)
            if (index < 0) {
                priceChangeHistoryDataList.splice(0, 0, getTheEntry(intervalSeconds, null))
            }
        })

        priceChangeHistoryDataList.sort((prev, next) => prev.x - next.x)
        priceChangeHistoryDataList.forEach((data, index) => {
            if (data.y === null) {
                const prevData = priceChangeHistoryDataList[index - 1],
                    nextData = priceChangeHistoryDataList.find((theData, theIndex) => theIndex > index && theData.y !== null),
                    prevValue = prevData ? prevData.y : null,
                    nextValue = nextData ? nextData.y : null

                if (prevValue !== null && nextValue !== null) {
                    data.y = (nextValue - prevValue) * (data.x - prevData.x) / (nextData.x - prevData.x) + prevValue
                }
            }
        })
    }

    return {
        priceTrendFlag,
        travelTipsHasHolidaysTips,
        travelTipsHasVisaTips,
        travelTipsHasHotSpotsList,
        onToggleExpand,
        pricePrediction,
        pricePredictionIndicatorWidth,
        tabHeaderPaddingLeft,
        onChangeActiveTag,
        priceTrendMapItem,
        activePricePrediction,
        pricePredictionDataList,
        priceChangeHistory,
        priceChangeHistoryIndicatorWidth,
        activePriceChangeHistory,
        priceChangeHistoryDataList,
        priceChangeTotalHours,
        travelTips,
        travelTipsIndicatorWidth,
        activeTravelTips,
        travelTipsTagName,
        travelTextClassName,
        onPopTravelSpotImage,
        tabHeaderIndicatorWidth,
        tabHeaderIndicatorLeft,
        expand
    }
}