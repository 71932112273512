import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { genEChartsOption } from '../../../sources/common/echartsOption'
import { processGuideLine } from '../result/flight/processGuideLine';

const genWrappedTitleEl = title => {
    if (title) {
        const title1 = title.substring(0, 4),
            title2 = title.substring(4, 8),
            title3 = title.substring(8)

        if (!title2) {
            return title1
        } else if (!title3) {
            return (
                <React.Fragment>
                    <p>{title1}</p>
                    <p>{title2}</p>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <p>{title1}</p>
                    <p>{title2.substring(0, 3)}...</p>
                </React.Fragment>
            )
        }
    } else {
        return null
    }
}

const getTrend_1 = (guideLineWrapper) => {
    const {
        pricePrediction,
        priceTrendMapItem,
        pricePredictionDataList,
        priceTrendFlag,
    } = guideLineWrapper
    let statusName = 'stable'
    if(priceTrendFlag < 0 ) {
        statusName = 'lower'
    }else if(priceTrendFlag > 0) {
        statusName = 'higher'
    }
    return pricePrediction ? (
        <li className={`future-trend ${statusName}`}>
            <div className='title'>{genWrappedTitleEl(priceTrendMapItem.floatSideTitle)}</div>
            <div className='content price-change-content'>
                <h3>
                    {priceTrendMapItem.plainTabHeader}<span className='hightline'>{priceTrendMapItem.tabContentHeader}</span>
                </h3>
                <div className='guide-chart'>
                    <ReactEcharts option={genEChartsOption(pricePredictionDataList, { addRMBIcon: true, conditionalLabel: true, showXAxis: true, smooth: true, showLabel: true, showSymbol: true, showYSplitLine: false, markPointTemplate: 'line', seriesSymbol: 'emptyCircle' })} style={{ width: "642px", height: "162px" }} />
                </div>
                <p className='des'>
                    *{priceTrendMapItem.tabContent}
                </p>
            </div>
        </li>
    ) : null
}

const getChangePrice_2 = (guideLineWrapper) => {
    const {
        priceChangeHistory,
        priceChangeHistoryDataList,
        priceChangeTotalHours,
    } = guideLineWrapper

    return priceChangeHistory ? (
        <li className='price-change'>
            <div className='title'>频繁变价</div>
            <div className='content price-change-content'>
                <h3>该航线最低价变动记录</h3>
                <div className='guide-chart'>
                    <ReactEcharts key={1} option={genEChartsOption(priceChangeHistoryDataList, { totalHours: priceChangeTotalHours, addRMBIcon: true, conditionalLabel: true, showXAxis: true, smooth: false, showLabel: false, showSymbol: true, showYSplitLine: true, markPointTemplate: 'circle', seriesSymbol: 'circle' })} style={{ width: "642px", height: "162px" }} />
                </div>
                <p className='des'>
                    *机票价格由中国民航信息网络股份有限公司、航空公司及供应商提供，价格变动与携程无关。不同价格的机票均有数量限制。
            </p>
            </div>
        </li>
    ) : null
}

const getHoliday_3 = (guideLineWrapper) => {
    const {
        travelTipsHasHolidaysTips,
        travelTipsHasVisaTips,
        travelTipsHasHotSpotsList,
        travelTips,
        onPopTravelSpotImage,
    } = guideLineWrapper

    let floatTitle = ''
    if (travelTipsHasVisaTips) {
        floatTitle = '出签容易'
    } else if (travelTipsHasHolidaysTips) {
        floatTitle = travelTips.getIn(['holidaysTips', 'title'], '').replace('赞! 正值', '')
    } else if (travelTipsHasHotSpotsList) {
        floatTitle = '旅行贴士'
    }

    // 三项信息都为空，返回null
    if (floatTitle) {
        return (
            <li className='festival'>
                <div className='title'>{genWrappedTitleEl(floatTitle)}</div>
                <div className='content festival-content'>
                    {travelTipsHasHolidaysTips && (
                        <div className='guide-text'>
                            <h3>{travelTips.getIn(['holidaysTips', 'title'])}</h3>
                            <div>{travelTips.getIn(['holidaysTips', 'description'])}</div>
                        </div>
                    )}
                    {travelTipsHasVisaTips && (
                        <div className='guide-text'>
                            <h3>{travelTips.getIn(['visaTips', 'title'])}</h3>
                            <div>{travelTips.getIn(['visaTips', 'description'])}</div>
                        </div>)}
                    {travelTipsHasHotSpotsList && (
                        <div className='guide-hot'>
                            <h3>当地热门</h3>
                            <ul className='guide-hot-list'>
                                {travelTips.get('hotSpotsList').map((item, index) => {
                                    const itemName = item.get('name'),
                                        itemNameWithFlag = '_' + itemName

                                    return (
                                        <React.Fragment key={itemNameWithFlag}>
                                            <li id={itemNameWithFlag}>
                                                <div className="pic" onClick={() => onPopTravelSpotImage(index, item)}>
                                                    <img src={item.get('image')} onError={ev => {
                                                        const currentSrc = ev.target.src,
                                                            rawImage = item.get('rawImage')

                                                        if (rawImage && currentSrc !== rawImage) {
                                                            ev.target.src = rawImage
                                                        } else {
                                                            const li = document.querySelector(`#${itemNameWithFlag}`)
                                                            if (li) {
                                                                li.style.display = 'none'
                                                            }
                                                        }
                                                    }}
                                                    alt=""/>
                                                </div>
                                                <p>{itemName}</p>
                                            </li>
                                        </React.Fragment>
                                    )
                                })}
                            </ul>
                        </div>)}
                </div>
            </li>
        )
    } else {
        return null
    }
}

const Guide = props => {
    const guideLineWrapper = processGuideLine(props)
    const trend = getTrend_1(guideLineWrapper)
    const priceChange = getChangePrice_2(guideLineWrapper)
    const holidy = getHoliday_3(guideLineWrapper)

    return (
        <React.Fragment>
            {trend}
            {priceChange}
            {holidy}
        </React.Fragment>
    )
};

export default Guide;
